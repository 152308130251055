<template>
  <div class="home">
    
    <home-header />

      <div class="platter copy-block">
        <h5>Looking for a reputable vendor with a strong
        background and history?</h5>
        <p>These ASPS-verified
        businesses provide the products and services 
        you need with the customer service experience 
        you deserve.</p>
      </div>

    <search-widget @loaded="searchWidgetLoaded = true" />

    <div class="collections pt-2">
      <div class="content">
        <collection-picker @loaded="collectionPickerLoaded = true" />
      </div>
    </div>

    <br />

    <div class="category pt-2">
      <div class="content">
        <category-picker @loaded="categoryPickerLoaded = true" />
      </div>
    </div>

    <br />

    <div class="favorite pt-2">
      <div class="content">
        <favorite-picker />
      </div>
    </div>
  </div>
</template>

<script>
import CategoryPicker from "@/components/Home/CategoryPicker.vue";
import CollectionPicker from "@/components/Home/CollectionPicker.vue";
import FavoritePicker from "@/components/Home/FavoritePicker.vue";
import SearchWidget from "@/components/Home/SearchWidget.vue";
import HomeHeader from "@/components/Home/HomeHeader";

export default {
  name: "Home",
  components: {
    CategoryPicker,
    CollectionPicker,
    FavoritePicker,
    SearchWidget,
    HomeHeader
  },
  computed: {
    loaded() {
      return (
        this.collectionPickerLoaded &&
        this.categoryPickerLoaded &&
        this.searchWidgetLoaded
      );
    }
  },
  watch: {
    loaded() {
      if (this.loaded) {
        this.$root.$emit("triggerScroll");
      }
    }
  },
  data() {
    return {
      collectionPickerLoaded: false,
      categoryPickerLoaded: false,
      searchWidgetLoaded: false
    };
  }
};
</script>

<style scoped lang="scss">
.content {
  max-width: 760px;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 576px) {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}

.copy-block {
  background: rgb(126 151 170);
  color: white;
  text-align: center;
  margin-bottom: -2px;

  h5 {
    font-weight: bolder;
    margin-bottom: 5px;
  }
  p {
    line-height: 1.2;
  }
  p:last-child {
    margin-bottom: 0;
  }
}
</style>